module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#f0e6f6',

  primary: '#1976d2',
  primaryLight: '#63a4ff',
  primaryDark: '#004ba0',

  secondary: '#616161',
  secondaryLight: '#8e8e8e',
  secondaryDark: '#373737',
};
